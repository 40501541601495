import styled from 'styled-components'
import Hero from './Hero'

const styledHero = styled(Hero)`
  & {
    margin-top: 50px;

    .copy {
      padding: 0 15px;
      text-align: center;
    }

    .button {
      margin-bottom: 32px;
    }


    .content-wrapper {
      display: grid;
    }


    @media screen and (min-width: 568px) {
      .content-wrapper {
        grid-template-columns: 1fr 1fr;
        align-items: center;
      }
      .copy {
        text-align: left;
      }
      .socials {
        margin: 0 auto 0 0;
      }
    }

    

  }

`

export default styledHero
