import styled from 'styled-components'
import About from './About'

const styledAbout = styled(About)`

  & {

    .about-hero {
      .content-wrapper {
        max-width: 600px;
        padding: 50px 15px;
        margin: unset;
        justify-self: right;
      }

      display: grid;
      @media screen and (min-width: 568px) {
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
      }

      @media screen and (min-width: 1201px) {
        grid-gap: 150px;
      }


      .hero-image-wrapper {


      }

    }

    .copy-block {
      .content-wrapper {
        max-width: 600px;

        &+.content-wrapper {
          margin-top: 100px;
        }
      }
    }

    .object-fit-image {
      display: block;
      min-height: 300px;
      max-height: 50vh;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center bottom;

      @media screen and (min-width: 568px) {
        min-height: 600px;
        max-height: 80vh;
      }
    }

  }

`

export default styledAbout
