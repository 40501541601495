import React from 'react'
import cx from 'classnames'

export default function Section(props:any) {
	const { 
		className,
		children,
		backgroundImage = '',
	} = props
	return <section 
		className={cx(className, )}
		style={{backgroundImage: `url('${backgroundImage}')`}}
	>
		<div className="content-wrapper">
			{children}
		</div>
	</section>
}