import cx from 'classnames'
import { NavLink } from 'react-router-dom'

export default function Button(props:any) {
	const { 
		ariaLabel = null,
		className,
		linkTo = null,
		isOutsideLink = false,
		style= 'primary',
		linkText = '',
		onClick= null,
		icon = null,
		isNav = false,
	} = props

	if(isNav) {
		return <NavLink
			to={linkTo}
			className={cx(className, 'button', style)} 
		>
			{linkText}
		</NavLink>
	}

	return <>
		{
			onClick !== null ? 
			<button 
				aria-live="polite"
				aria-label={ariaLabel ? ariaLabel : ''}
				className={cx(className, 'button', style)} 
				onClick={onClick}
			>
				{icon}
			</button>
			: <a 
				className={cx(className, 'button', style)} 
				href={linkTo} 
				target={isOutsideLink ? '_blank' : '_self'}
				rel={isOutsideLink ? 'noopener noreferrer' : ''}
			>
				{linkText}
			</a>
		}
	</>
}