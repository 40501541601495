import cx from 'classnames'
import Section from '../../components/Section'
import Hero from '../../components/Hero'
import Button from '../../components/Button';

export default function Home(props:any) {

	const images = process.env.PUBLIC_URL + '/images/';

 const { className } = props
	return <div className={cx(className)}>

		<Hero />

		<div className="about-wrapper">

			<Section>
				<div className="content-wrapper about">
					<img 
						src={`${images}collab.webp`} 
						alt="Mika collaborating with a team"
					/>
					<div>
						<h2>I like to work with people who build for people</h2>
						<p>With more than six years of professional development experience, I have refined my collaborative skills, working seamlessly with design teams, back-end developers, and key stakeholders. My technical expertise ensures that every user experience I deliver is accessible to all, irrespective of their browser preference, device platform, or assistive tools, underlining the principle that the internet is an inclusive space for everyone.</p>
						{/* <Button 
							linkTo="/about" 
							linkText={'Learn more about Mika'} 
							style={'onPrimary'}
						/> */}
						<Button 
							linkTo="/about" 
							linkText={'Learn more about Mika'} 
							style={'onPrimary'}
							isNav
						/>
					</div>
				</div>
			</Section>

		</div>


		<div id="work">
			<Section> 
				<div className="content-wrapper">
					<div>
						<h2>WordPress Wiz</h2>
						<p>Explore a selection of my work, featuring beautifully crafted custom WordPress themes for clients. <span className="italic">I'm still working on some client projects so check back soon.</span></p>
					</div>

					<div className="sites">
						<div>
							<div className="media">
								<img loading="lazy"  src={`${images}projects/digworld_desktop.webp`} alt="Desktop view of Digworld Site" className="desktop mb-6" />
								<img loading="lazy"  src={`${images}projects/digworld_mobile.webp`} alt="Mobile view of Digworld Site" className="mobile" />
							</div>
							<div className="copy">
								<h3 className='mb-1'>Dig World TX</h3>
								<p>Dig World allows kids to safely operate heavy equipment construction equipment.</p>
								<Button 
									style="onBg"
									isOutsideLink={true} 
									linkTo="https://www.digworldtx.com/" 
									linkText={'Visit Dig World TX'} 
								/>
							</div>
						</div>

						{/* <div>
							<div className="media">
								<img loading="lazy"  src={`${images}projects/alpha_desktop.webp`} alt="Desktop view of Alpha Barbell Site" className="desktop mb-6" />
								<img loading="lazy"  src={`${images}projects/alpha_mobile.webp`} alt="Mobile view of Alpha Barbell Site" className="mobile" />
							</div>
							<div className="copy">
								<h3 className='mb-1'>Alpha Barbell</h3>
								<p>Alpha Barbell offers proven, customized coaching in a fun, supportive community. Work In Progress...</p>
								<Button 
									style="onBg"
									isOutsideLink={true} 
									linkTo="https://www.teamalphabarbell.com/" 
									linkText={'Visit Alpha Barbell'} 
								/>
							</div>
						</div> */}


						{/* <div>
							<div className="media">
								<img loading="lazy"  src={`${images}projects/expo_desktop.webp`} alt="Desktop view of Houston Expo Site" className="desktop mb-6" />
								<img loading="lazy"  src={`${images}projects/expo_mobile.webp`} alt="Mobile view of Houston Expo Site" className="mobile" />
							</div>
							<div className="copy">
								<h3 className='mb-1'>Houston Expo</h3>
								<p>Hello World</p>
								<Button 
									style="onBg"
									isOutsideLink={true} 
									linkTo="https://www.teamalphabarbell.com/" 
									linkText={'Visit Houston Expo'} 
								/>
							</div>
						</div> */}


					</div>

					<div className='web-apps'>
						<h2>Web Apps for the win</h2>
						<p>I've had the pleasure of working with amazing teams to launch user focused features. Connect with me and I'd be happy to chat about my experience with 'em.</p>
					</div>

					<div className="sites">
						<div>
							<div className="media">
								<img loading="lazy"  src={`${images}projects/alice_desktop.webp`} alt="Desktop view of Hello Alice Web App" className="desktop mb-6" />
								<img loading="lazy"  src={`${images}projects/alice_mobile.webp`} alt="Mobile view of Hello Alice Web App" className="mobile" />
							</div>
							<div className="copy">
								<h3>Hello Alice</h3>
								<p>Sign up and join a community of small business owners and take your business to the next level.</p>
								<Button 
									isOutsideLink={true} 
									style="onBg"
									linkTo="https://app.helloalice.com/login?_gl=1*rfkji3*_ga*NzY2MjI3Mzg2LjE2OTY0NjU1ODA.*_ga_LTGLZCEVC2*MTY5NjQ2NTU4MC4xLjEuMTY5NjQ2NTkzMS4wLjAuMA.." 
									linkText={'Sign up for Hello Alice'} 
								/>
							</div>
						</div>
					</div>

				</div>
			</Section>
		</div>


		
	</div>
}