import cx from 'classnames'
import LinkedIn from '../../components/icons/LinkedIn'
import Github from '../../components/icons/Github'
import Codepen from '../../components/icons/Codepen'

export default function Socials(props:any) {
	const { 
		className,
		style = 'onBg'
	} = props
	return <ul className={cx(className, 'socials', style)}>
			<li>
				<a 
					aria-label="Linked In" 
					target="_blank" 
					rel="noopener noreferrer" 
					href="https://www.linkedin.com/in/workwithmeekuh/"
				>
					<LinkedIn />
				</a>
			</li>
			<li>
				<a 
					aria-label="Git hub" 
					target="_blank" 
					rel="noopener noreferrer" 
					href="https://github.com/MikaGurrola"
				>
					<Github />
				</a>
			</li>
			<li>
				<a 
					aria-label="Code pen" 
					target="_blank" 
					rel="noopener noreferrer" 
					href="https://codepen.io/MikaGurrola/"
				>
					<Codepen />
				</a>
			</li>
	</ul>
}