import styled from 'styled-components'
import Socials from './Socials'

const styledSocials = styled(Socials)`

  & {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, auto);
    width: fit-content;
    margin: 0 auto;

    svg {
      width: 24px;
      height: auto;
      fill: var(--onBg);
    }

    a {
      display: flex;
      width: 28px;
      align-items: center;
      justify-content: center;
    }

    a:focus:focus-visible {
      outline: 2px solid var(--onBg);
      outline-offset: 2px;
      border-radius: var(--border-radius);
    }


    &.bg {
      svg {
        fill: var(--bg);
      }

      a:focus:focus-visible {
        outline: 2px solid var(--bg);
        outline-offset: 2px;
        border-radius: var(--border-radius);
      }
    }

    &.onBg {
      svg {
        fill: var(--onBg);
      }

      a:focus:focus-visible {
        outline: 2px solid var(--onBg);
        outline-offset: 2px;
        border-radius: var(--border-radius);
      }
    }

    
    
    &.primary {
      svg {
        fill: var(--primary);
      }

      a:focus:focus-visible {
        outline: 2px solid var(--primary);
        outline-offset: 2px;
        border-radius: var(--border-radius);
      }
    }

    &.onPrimary {
      svg {
        fill: var(--onPrimary);
      }

      a:focus:focus-visible {
        outline: 2px solid var(--onPrimary);
        outline-offset: 2px;
        border-radius: var(--border-radius);
      }
    }


    &.secondary {
      svg {
        fill: var(--secondary);
      }

      a:focus:focus-visible {
        outline: 2px solid var(--secondary);
        outline-offset: 2px;
        border-radius: var(--border-radius);
      }
    }

    &.onSecondary {
      svg {
        fill: var(--onSecondary);
      }

      a:focus:focus-visible {
        outline: 2px solid var(--onSecondary);
        outline-offset: 2px;
        border-radius: var(--border-radius);
      }
    }

  }

`

export default styledSocials
