import cx from 'classnames'
import Socials from '../Socials'

const images = process.env.PUBLIC_URL + '/images/';

export default function Hero(props:any) {
	const { className } = props
	return <div className={cx(className)}>
			<div className="content-wrapper">
				<div className="copy">
					<h1>Hi, I'm Mika</h1>
					<p className='mb-8'>I develop digitally accessible web experiences. Currently I'm open for work.</p>
					<Socials style="onBg" />
				</div>
				<img 
					src={`${images}mika.webp`} 
					alt="a not so professional head shot of mika"
				/>
			</div>
	</div>
}