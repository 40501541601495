import cx from 'classnames'
import Section from '../../components/Section'

export default function About(props:any) {
	const { className } = props
	const images = process.env.PUBLIC_URL + '/images/';


	return <div className={cx(className)}>


		<div className='about-hero'>
			<article className="content-wrapper">
				<h1>Get to know the person behind the pixels</h1>
				<p>Welcome to my corner of the web! Learn more about how I go here and what I'm doing when I'm not debuggin or compiling code.</p>
			</article>
			<img
				loading="lazy" 
				className='object-fit-image'
				src={`${images}mika_mac.webp`} 
				alt="Mika works at a coffee shop called the anthem. His macbook cover flooded with stickers." 
			/>
		</div>


		<div className='copy-block'>
			<img
				loading="lazy"  className='object-fit-image' src={`${images}guam.webp`} alt="A Guam shoreline at twilight with traditional fishing boats." />

			<Section>
				<div className="content-wrapper">
					<h2>From the Pacific shores to the Pacific Northwest</h2>
						<p>Growing up on a small rock in the middle of the ocean, I found a curiousity for browsing the world via the internet, even in the golden age of dial up. I also learned to see the world through the lens of digital cameras and even today I lean on the camera I have with all the time, my phone.</p>
						
						<p>My hobbies include: my cherished plants thrive under my care, taking my trusty canine companion (Houston) with me on hikes exploring new trails, picking heaving things up and putting them back down and savoring the intricate flavors of craft bars.</p>
				</div>

				<div className="content-wrapper">
					<h2>My Professional Journey</h2>
					<p>Ever reminisce about the MySpace days, when we meticulously curated sparkly marquee profiles and where choosing your top 8 friends felt like a major life decision? That's where my journey into web development began. I harnessed that early fascination, delving into the world of web design and development at Full Sail University, culminating in a Bachelor's degree in computer science.</p>
					
					<p>Post-graduation, I jumped headfirst into the dynamic realm of digital marketing, sculpting custom experiences for diverse brands. The high-pressure environment of a marketing agency taught me to pivot swiftly, adapt strategically, and understand a brands' core aspirations. Those long hours and quick turnarounds? They paid off. I found immense joy in launching unique projects, shoulder-to-shoulder with a small, dedicated team.</p>

					<p>Eager for a more focused singular product that reach real-world users, I found my niche in the fast-paced world of startups. The energy, the cutting-edge tech, and the ideal work-life balance were a perfect fit for me. Crafting user experiences that are both user-friendly and delightful became my passion.</p>
					
				</div>
			</Section>
		</div>


	</div>
}