import { Outlet } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';

export default function Root(props:any) {
 const { className } = props
    return <div>
			<Header />
			<div id="body-content">
				<Outlet />
			</div>
			<Footer />
  </div>
}