import styled from 'styled-components'
import Header from './Header'

const styledHeader = styled(Header)`


  &.header {
    .nav-items {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 20px;
      align-items: flex-end;
      width: fit-content;
      margin-left: auto;
  
      a {
        padding: 5px 15px;
        text-transform: uppercase;
        border-radius: var(--border-radius);
        display: block;
        line-height: 24px;
  
        &.active {
          text-decoration: underline;
        }
  
        &:focus:focus-visible {
          outline: 2px solid var(--onBg);
          outline-offset: 2px;
          border-radius: var(--border-radius);
        }
      }
    }
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    button {
      padding: 5px;
      margin-right: 15px;
      svg {
      width: 24px;
      height: 24px;
    }
    }
  }

`

export default styledHeader
