import styled from 'styled-components'
import Home from './Home'

const styledHome = styled(Home)`
  
  
  .about-wrapper {
    background: var(--primary);
    h2, p, a {
      color: var(--onPrimary);
    }
  }

  .about {
    display: grid;
    grid-gap: 50px;

  }
  
  @media screen and (min-width: 568px) {
    .about {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: center;
      justify-items: center;
      grid-gap: 100px;
    }
  }


  .sites {
    display: grid;
    grid-gap: 100px;
    margin-top: 64px;

    > div {
      position: relative;

      img, div.img {
        color: var(--bg);
        border: 4px solid var(--onBg);
        border-radius: var(--border-radius);


        &.desktop {
          /* aspect-ratio: 16 / 9; */
        }
        &.mobile {
          /* aspect-ratio: 1 / 2; */
          max-width: 30%;
          position: absolute;
          top: 80px;
          right: 15px;
          box-shadow: var(--box-shadow);
        }
      }

      .copy {
        margin-top: 32px;
      }
    }


    @media screen and (min-width: 568px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 1201px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }


  .web-apps {
    margin-top: 100px;
    @media screen and (min-width: 568px) {
      margin-top: 200px;
    }
  }

`

export default styledHome
