import cx from 'classnames'
import Socials from '../Socials'

export default function Footer(props:any) {
 const { className } = props
	return <footer className={cx(className)}>
		<div className="content-wrapper">
			<h2>Let's Connect!</h2>
			<p>Got a project in mind, a question, or just want to say hello, I'm here and open for collaboration. Feel free to message me on the socials!</p>
			<Socials style="onSecondary" />
			</div>
	</footer>
}