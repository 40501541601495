import styled from 'styled-components'
import Footer from './Footer'

const styledFooter = styled(Footer)`
  padding: 100px 15px;
  background: var(--secondary);
  margin-top: 64px;


  h2, p {
    color: var(--onSecondary);
  }

  .socials {
    margin: 20px auto 0 0;
  }
`

export default styledFooter
