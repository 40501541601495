import styled from 'styled-components'
import Section from './Section'

const styledSection = styled(Section)`

  & {
    min-height: 80vh;
    padding: 50px 15px;
    display: grid;
    align-items: center;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: 568px) {
      min-height: 50vh;
      padding: 150px 15px;
    }
  }

`

export default styledSection
