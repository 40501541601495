import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import DarkModeToggle from '../icons/DarkModeToggle'
import Button from '../Button'
import { useEffect, useState } from 'react'


export default function Header(props:any) {
	const { className } = props
	const [isDark, setIsDark] = useState(false)
	const toggleTheme = () => {
		console.log('toggle themeeee', isDark)
		setIsDark(!isDark)
	}

	const setTheme = (theme: string) => {
		document.documentElement.classList.toggle(theme)
	}

	const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)')

	useEffect(() => {
		if(prefersDarkTheme) setTheme('isDark')
	}, [])

	useEffect(() => {
		setTheme('isDark')
	}, [isDark])


	return <div className={cx(className, 'header')}>
		<nav className="p-5">
			<ul className='nav-items'>
				<li>
					<NavLink
						to="/"
						className={({ isActive, isPending }) =>
							isPending ? "pending" : isActive ? "active" : ""
						}
					>
						Home
					</NavLink>
				</li>
				<li>
					<NavLink
						to="/about"
						className={({ isActive, isPending }) =>
							isPending ? "pending" : isActive ? "active" : ""
						}
					>
						About
					</NavLink>
				</li>
			</ul>
		</nav>

		<Button
			style="onBg onBg--ghost"
			onClick={toggleTheme}
			ariaLabel={`Toggle Dark mode ${isDark ? 'off' : 'on'}`}
			icon={<DarkModeToggle />}
		/>

	</div>
	
}