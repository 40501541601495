import cx from 'classnames'
import { useRouteError } from "react-router-dom";
import Section from '../../components/Section';
import { NavLink } from 'react-router-dom'

export default function ErrorPage(props:any) {
  const { className } = props
  const error:any = useRouteError();
  console.error(error);

  return <div className={cx(className)}>
    <Section>
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p> <i>{error.statusText || error.message}</i> </p>
        <NavLink
          to="/"
        >
          Go back Home
        </NavLink>
      </div>
    </Section>
  </div>
}